import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import  Container from "../components/Container"

const ThankYou = (lang) => (
  <Container>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', gap: 60, textAlign: 'center'}}>
      <h1>Thank You for contacting us!</h1>
      <p>We will get back to you within 72 hours.</p>
      <PrismicLink href="/contact" className="BtnPrimary">
        Return to Contact
      </PrismicLink>
    </div>
  </Container>
)

export default withPrismicUnpublishedPreview(ThankYou)
